import React from 'react';

export default function VideoLogo() {
  return (
    <img
      alt="My Doctors Live logo"
      src="https://mydoctorslive.com/wp-content/uploads/2020/12/MDL.Logo_.StackedNEWUpdated.png"
      style={{ width: 278 * 0.5, height: 142 * 0.5 }}
    />
    //     <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    //       width="278.000000pt" height="142.000000pt" viewBox="0 0 278.000000 142.000000"
    //       preserveAspectRatio="xMidYMid meet">

    //       <g transform="translate(0.000000,142.000000) scale(0.100000,-0.100000)"
    //         fill="#000000" stroke="none">
    //         <path d="M1163 778 c-41 -11 -73 -52 -73 -94 0 -50 9 -64 41 -64 22 0 29 5 29
    // 19 0 11 5 23 10 26 6 4 10 -7 10 -25 0 -53 17 -24 24 41 8 68 16 70 27 6 6
    // -35 8 -39 14 -19 9 27 25 29 25 3 0 -23 28 -54 41 -46 5 4 9 15 9 26 0 10 5
    // 19 10 19 6 0 10 -9 10 -19 0 -11 6 -22 13 -25 6 -4 2 -4 -10 -2 -17 3 -23 -1
    // -23 -14 0 -11 -4 -22 -10 -25 -5 -3 -10 3 -10 14 0 12 -5 21 -11 21 -7 0 -18
    // 8 -25 18 -12 16 -13 16 -14 -4 0 -41 -15 -34 -26 13 l-10 48 -8 -73 c-8 -75
    // -26 -109 -26 -48 0 40 -5 45 -43 37 -27 -6 -27 -7 56 -89 l84 -84 87 94 c48
    // 51 90 104 93 117 9 37 -9 92 -38 114 -32 23 -90 25 -123 4 -15 -10 -27 -12
    // -33 -6 -17 17 -67 26 -100 17z"/>
    //         <path d="M2118 705 c-54 -30 -73 -102 -41 -155 34 -59 125 -69 174 -21 16 16
    // 29 35 29 43 0 8 -4 6 -11 -5 -16 -28 -31 -9 -24 33 5 31 2 42 -20 68 -31 37
    // -14 43 24 10 l26 -22 -26 28 c-32 34 -91 44 -131 21z"/>
    //       </g>
    //     </svg>
    // <svg xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96">
    //   <g fill="none" fillRule="evenodd">
    //     <path
    //       fill="#FFF"
    //       d="M43.86 0h8.28c11.661 0 18.564 1.74 24.675 5.01 6.112 3.267 10.908 8.063 14.175 14.175C94.26 25.295 96 32.197 96 43.86v8.28c0 11.661-1.74 18.564-5.01 24.675-3.267 6.112-8.063 10.908-14.175 14.175C70.704 94.26 63.801 96 52.14 96h-8.28c-11.661 0-18.564-1.74-24.675-5.01-6.112-3.267-10.908-8.063-14.175-14.175C1.74 70.705 0 63.803 0 52.14v-8.28c0-11.661 1.74-18.564 5.01-24.675C8.277 13.073 13.073 8.277 19.185 5.01 25.295 1.74 32.197 0 43.86 0z"
    //     />
    //     <path
    //       fill="#F22F46"
    //       d="M53.25 63h-25.5C25.679 63 24 61.321 24 59.25v-22.5c0-2.071 1.679-3.75 3.75-3.75h25.5c2.071 0 3.75 1.679 3.75 3.75v5.82l9.57-4.785c1.164-.583 2.546-.521 3.653.164 1.107.685 1.78 1.894 1.777 3.196V54.87c.008 1.3-.66 2.512-1.766 3.198-1.105.686-2.487.747-3.65.162L57 53.49v5.76C57 61.321 55.32 63 53.25 63zm-25.5-27c-.414 0-.75.336-.75.75v22.5c0 .414.336.75.75.75h25.5c.414 0 .75-.336.75-.75v-8.175c-.001-.518.266-1 .705-1.275.44-.275.99-.303 1.455-.075L67.92 55.5c.224.123.496.123.72 0 .22-.141.356-.383.36-.645v-13.71c-.004-.262-.14-.504-.36-.645-.224-.123-.496-.123-.72 0l-11.745 5.865c-.47.236-1.028.21-1.473-.069-.445-.28-.711-.77-.702-1.296v-8.25c0-.414-.336-.75-.75-.75h-25.5z"
    //     />
    //   </g>
    // </svg>
  );
}
