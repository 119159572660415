import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
import IntroContainer from '../IntroContainer/IntroContainer';
import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { VideoCallApiHelper } from '../../api/api';
import MeetingAliasInvalid from '../MeetingAliasInvalid/MeetingAliasInvalid';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}

export default function PreJoinScreens() {
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName, MeetingId } = useParams();
  const [step, setStep] = useState(Steps.roomNameStep);

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [roomDesc, setRoomDesc] = useState<string>('');
  const [userRole, setUserRole] = useState<string>('');
  const [meetingAliasInvalid, setMeetingAliasInvalid] = useState<boolean>(false);

  const [mediaError, setMediaError] = useState<Error>();

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
      if (user?.displayName) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, URLRoomName]);

  useEffect(() => {
    console.log(`MeetingId `, MeetingId);
    if (!!MeetingId) {
      // setRoomName(URLRoomName);
      // if (user?.displayName) {
      //   setStep(Steps.deviceSelectionStep);
      // }
      //1. check alias from url param
      VideoCallApiHelper.fetchInstantVideoCallInfo(MeetingId)
        .then(room_info_data => {
          console.log(`meeting info `, room_info_data);
          if (!!room_info_data) {
            setRoomDesc(room_info_data.data.room_desc);
            setRoomName(MeetingId);
            // setStep(Steps.deviceSelectionStep);
            //2. if success then request video token by alias
            var _fullname = 'Demo Patient';
            var _role = 'patient';
            VideoCallApiHelper.requestVideoTokenByAlias(MeetingId, _fullname, _role).then(auth_data => {
              console.log(`requestVideoTokenByAlias `, auth_data);
              if (auth_data) {
                //success then
                setName(auth_data.data.my_profile?.name);
                setUserRole(auth_data.data.my_profile?.role);
                setStep(Steps.deviceSelectionStep);
              }
            });
          } else {
            setMeetingAliasInvalid(true);
          }
        })
        .catch(err => {
          //meeting alias invalid
          console.error(err);
          setMeetingAliasInvalid(true);
          // setIsError(true);
        });
      return () => {};
    } else {
      setMeetingAliasInvalid(true);
    }
  }, [user, MeetingId]);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.log('Error acquiring local media:');
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    if (!window.location.origin.includes('twil.io')) {
      window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}${window.location.search || ''}`));
    }
    setStep(Steps.deviceSelectionStep);
  };

  const _handleDismiss = () => {
    // handleIgnoreConsent();
  };

  return (
    <IntroContainer>
      <MediaErrorSnackbar error={mediaError} />
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          roomName={roomName}
          roomDesc={roomDesc}
          setName={setName}
          setRoomName={setRoomName}
          handleSubmit={handleSubmit}
        />
      )}

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen
          name={name}
          roomDesc={roomDesc}
          roomName={roomName}
          userRole={userRole}
          setStep={setStep}
        />
      )}

      {meetingAliasInvalid === true && <MeetingAliasInvalid handleDismiss={_handleDismiss}></MeetingAliasInvalid>}
    </IntroContainer>
  );
}
