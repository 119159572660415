import React from 'react';
// import clsx from 'clsx';
// import { makeStyles, Theme } from '@material-ui/core';
// import useChatContext from '../../hooks/useChatContext/useChatContext';
// import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Modal } from 'react-bootstrap';
// import Button from 'react-bootstrap/Button';

// const useStyles = makeStyles((theme: Theme) => {
//   const totalMobileSidebarHeight = `${theme.sidebarMobileHeight +
//     theme.sidebarMobilePadding * 2 +
//     theme.participantBorderWidth}px`;
//   return {
//     container: {
//       position: 'relative',
//       height: '100%',
//       display: 'grid',
//       gridTemplateColumns: `1fr ${theme.sidebarWidth}px`,
//       gridTemplateRows: '100%',
//       [theme.breakpoints.down('sm')]: {
//         gridTemplateColumns: `100%`,
//         gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`,
//       },
//     },
//     rightDrawerOpen: { gridTemplateColumns: `1fr ${theme.sidebarWidth}px ${theme.rightDrawerWidth}px` },
//   };
// });

type MeetingAliasInvalidProps = {
  handleDismiss: any;
};

/**
 * #37758 - Hybrid Video Call Flow
 * @param param0
 * @returns
 */
export default function MeetingAliasInvalid({ handleDismiss }: MeetingAliasInvalidProps) {
  // const classes = useStyles();
  // const _handleClose = () => {
  //   handleIgnoreConsent();
  // };

  // const _handleConfirmConsent = () => {
  //   handleConfirmConsent();
  // };

  return (
    <Modal show={true} backdrop="static" keyboard={false} size="lg" centered>
      <Modal.Header>
        <Modal.Title>You do not have access to this</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Please check with the person who shared it with you to see if there's another link available.</p>
      </Modal.Body>
    </Modal>
  );
}
