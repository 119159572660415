import React from 'react';
// import clsx from 'clsx';
// import { makeStyles, Theme } from '@material-ui/core';
// import useChatContext from '../../hooks/useChatContext/useChatContext';
// import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

// const useStyles = makeStyles((theme: Theme) => {
//   const totalMobileSidebarHeight = `${theme.sidebarMobileHeight +
//     theme.sidebarMobilePadding * 2 +
//     theme.participantBorderWidth}px`;
//   return {
//     container: {
//       position: 'relative',
//       height: '100%',
//       display: 'grid',
//       gridTemplateColumns: `1fr ${theme.sidebarWidth}px`,
//       gridTemplateRows: '100%',
//       [theme.breakpoints.down('sm')]: {
//         gridTemplateColumns: `100%`,
//         gridTemplateRows: `calc(100% - ${totalMobileSidebarHeight}) ${totalMobileSidebarHeight}`,
//       },
//     },
//     rightDrawerOpen: { gridTemplateColumns: `1fr ${theme.sidebarWidth}px ${theme.rightDrawerWidth}px` },
//   };
// });

type VideoConsentProps = {
  handleIgnoreConsent: any;
  handleConfirmConsent: any;
};

/**
 * #37758 - Hybrid Video Call Flow
 * @param param0
 * @returns
 */
export default function VideoConsent({ handleIgnoreConsent, handleConfirmConsent }: VideoConsentProps) {
  // const classes = useStyles();
  const _handleClose = () => {
    handleIgnoreConsent();
  };

  const _handleConfirmConsent = () => {
    handleConfirmConsent();
  };

  return (
    <Modal show={true} onHide={_handleClose} backdrop="static" keyboard={false} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>Telehealth Consent</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Telehealth (also called telemedicine) is a way to visit with your healthcare provider without going to a
          hospital or clinic. The visits are held by computer, tablet, or telephone.
        </p>
        <p>
          This form gives permission for telehealth communication between:
          <ul>
            <li>
              <strong>My Doctor's Live</strong> and
            </li>
            <li>
              <strong>Ian Morrison</strong> - DOB: <strong>30 Apr 1950</strong>
            </li>
          </ul>
        </p>
        <p>
          I understand that a telehealth involves sharing my medical/mental health information electronically. I will
          tell my healthcare provider if there is any information that I do not want to talk about in a telehealth
          visit.
        </p>
        <p>
          I understand that I may stop the telehealth visit at any time. If I decide to stop, I will still be able to
          have medical care at this office.
        </p>
        <p>
          I understand that I may have to check with my health insurance plan to see if telehealth visits are covered.
        </p>
        <p>
          I understand that telehealth visits carry some level of risk. These risks include but are not limited to:
          <ul>
            <li>
              My computer, tablet, or phone may not be private and secure, especially if other people use it. It is my
              responsibility to make sure my internet system is private and secure and to make sure I am in a private
              place during the visit.
            </li>
            <li>Technical problems may interrupt or stop the visit before it is done.</li>
            <li>
              My healthcare provider cannot examine me as closely during a telehealth visit, and this may make it harder
              to determine what is wrong with me.
            </li>
          </ul>
        </p>
        <p>
          I agree that information shared during my telehealth visit will be kept by the healthcare providers and
          facilities involved in my care.
        </p>
        <p>I understand that the telehealth visit will or will not (circle one) be recorded.</p>
        <p>
          I understand that I will be asked to confirm my identity and current location to the healthcare provider
          seeing me.
        </p>
        <p>
          I also have the right to confirm the identity and credentials of the healthcare provider who will be seeing
          me.
        </p>
        <p>
          I agree to follow my healthcare provider’s recommendations--including lab tests and x-rays, sending me to a
          specialist, or asking me to come to the office or go to an emergency department for an in-person visit.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={_handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={_handleConfirmConsent}>
          I agree
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
