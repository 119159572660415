import axios, { AxiosResponse } from 'axios';
import { GenericResp, VideoAliasInfoResp, VideoTokenResp } from '../models/generic-resp.interface';
//https://javascript.plainenglish.io/frontend-api-calls-with-typescript-and-axios-68792d1e63c2
// const _BASE_URL = 'http://localhost:3000/';
const _BASE_URL = 'https://digital-contact-center.gkim.digital/';
const instance = axios.create({
  baseURL: _BASE_URL,
  timeout: 15000,
});

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
  get: (url: string) => instance.get(url).then(responseBody),
  post: (url: string, body: {}) => instance.post(url, body).then(responseBody),
  put: (url: string, body: {}) => instance.put(url, body).then(responseBody),
  delete: (url: string) => instance.delete(url).then(responseBody),
};

export const VideoCallApiHelper = {
  fetchInstantVideoCallInfo: (alias_slug: String): Promise<VideoAliasInfoResp> =>
    requests.get(`video/fetch-instant-video-call-info/${alias_slug}`),
  requestNewVideoCall: (alias_slug: String, full_name: String, role: String): Promise<GenericResp> =>
    requests.post(`video/request-new-video-call/${alias_slug}`, { name: full_name, role: role }),
  requestVideoTokenByAlias: (alias_slug: String, full_name: String, role: String): Promise<VideoTokenResp> =>
    requests.post(`video/video-token-by-alias/${alias_slug}`, { name: full_name, role: role }),
  endVideoCall: (alias_slug: String, full_name: String, role: String): Promise<GenericResp> =>
    requests.post(`video/end-video-call/${alias_slug}`, { name: full_name, role: role }),
  // getAPost: (id: number): Promise<PostType> => requests.get(`posts/${id}`),
  // createPost: (post: PostType): Promise<PostType> =>
  //   requests.post('posts', post),
  // updatePost: (post: PostType, id: number): Promise<PostType> =>
  //   requests.put(`posts/${id}`, post),
  // deletePost: (id: number): Promise<void> => requests.delete(`posts/${id}`),
};
